import React, { useCallback } from 'react';
import { graphql, usePreloadedQuery, type PreloadedQuery } from 'react-relay';
import { Inline } from '@atlaskit/primitives';
import FilterRefinement from '@atlassian/jira-filter-refinement/src/async.tsx';
import type { boardFilterRefinementQuery } from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementQuery.graphql';
import { useIntl } from '@atlassian/jira-intl';
import { filteredCardsSuccess } from '../../../../state/actions/card/filtered-cards/index.tsx';
import { boardIssuesSelector } from '../../../../state/selectors/issue/board-issue-selectors.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../state/index.tsx';
import messages from './messages.tsx';

type Props = {
	boardFilterRefinementQueryRef: PreloadedQuery<boardFilterRefinementQuery>;
};

export const BoardFilterRefinement = ({ boardFilterRefinementQueryRef }: Props) => {
	const { formatMessage } = useIntl();
	const issues = useBoardSelector(boardIssuesSelector);
	const dispatch = useBoardDispatch();

	const onSearch = useCallback(
		(_newJql: string) => {
			const issueIds = Object.keys(issues);

			dispatch(filteredCardsSuccess(issueIds));
		},
		[dispatch, issues],
	);

	const data = usePreloadedQuery(
		graphql`
			query boardFilterRefinementQuery($cloudId: ID!) @preloadable {
				...ui_filterRefinement_FilterRefinement @arguments(cloudId: $cloudId)
			}
		`,
		boardFilterRefinementQueryRef,
	);

	return (
		<Inline>
			<FilterRefinement
				defaultJql=""
				onSearch={onSearch}
				fragmentRef={data}
				searchFieldPlaceholder={formatMessage(messages.filterRefinementSearchFieldPlaceholder)}
			/>
		</Inline>
	);
};
