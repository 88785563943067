import { type Reducer, combineReducers } from 'redux';
import type { Action } from '../../types.tsx';
import assignIssueParentModal from './assign-issue-parent-modal/reducer.tsx';
import { backlogEnableDialogReducer } from './backlog-enable-dialog/reducer.tsx';
import { backlogIssueMoveDialogReducer } from './backlog-issue-move-dialog/reducer.tsx';
import boardReducers from './board/reducer.tsx';
import cardsReducer from './cards/reducer.tsx';
import columnLimitsModal from './column/limits-modal/reducer.tsx';
import columnReducers from './column/reducer.tsx';
import { columnsUIReducer } from './columns/reducer.tsx';
import { flagsReducer } from './flags/reducer.tsx';
import globalIssueCreateReducer from './global-issue-create/reducer.tsx';
import issueAddLabelModal from './issue-add-label-modal/reducer.tsx';
import { issueDeleteModalReducer } from './issue-delete-modal/reducer.tsx';
import { issueFlagWithCommentModalReducer } from './issue-flag-with-comment-modal/reducer.tsx';
import { issueModalReducer } from './issue-modal/reducer.tsx';
import issueTransitionModal from './issue-transition-modal/reducer.tsx';
import panelsReducer from './panels/reducer.tsx';
import { planIssueModalReducer } from './plan-issue-modal/reducer.tsx';
import { sprintAssociationModalReducer } from './sprint-association-modal/reducer.tsx';
import { sprintsReducer } from './sprint/reducer.tsx';
import swimlaneReducers from './swimlane/reducer.tsx';
import type { UiState } from './types.tsx';
import { updateIssueParentModalReducer } from './update-issue-parent-modal/reducer.tsx';
import { workFiltersReducer } from './work-filters/reducer.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (): Reducer<UiState, Action> =>
	combineReducers<UiState, Action>({
		backlogIssueMoveDialog: backlogIssueMoveDialogReducer,
		backlogEnableDialog: backlogEnableDialogReducer,
		board: boardReducers,
		column: columnReducers,
		columns: columnsUIReducer,
		flags: flagsReducer,
		issueModal: issueModalReducer,
		planIssueModal: planIssueModalReducer,
		issueDeleteModal: issueDeleteModalReducer,
		swimlane: swimlaneReducers,
		workFilters: workFiltersReducer,
		cards: cardsReducer,
		issueAddLabelModal,
		issueTransitionModal,
		assignIssueParentModal,
		columnLimits: columnLimitsModal,
		panels: panelsReducer,
		sprints: sprintsReducer,
		globalIssueCreate: globalIssueCreateReducer,
		updateIssueParentModal: updateIssueParentModalReducer,
		issueFlagWithCommentModal: issueFlagWithCommentModalReducer,
		sprintAssociationModal: sprintAssociationModalReducer,
	});
