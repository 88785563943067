/** @jsx jsx */
import React, { useState, useMemo, useCallback, type ChangeEvent } from 'react';
import { jsx } from '@compiled/react';
import flatten from 'lodash/flatten';
import mapValues from 'lodash/mapValues';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import AddIcon from '@atlaskit/icon/core/add';
import AddIconOld from '@atlaskit/icon/glyph/add';
import { Box, Stack, Text, xcss, Inline } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueLinkedModalEntrypointActions } from '@atlassian/jira-issue-links-common/src/controllers/issue-links-modal-entrypoint-store/index.tsx';
import type { IssueLink } from '@atlassian/jira-issue-links-common/src/types.tsx';
import { IssueLinksDetailsGroup } from '@atlassian/jira-issue-links-details/src/ui/index.tsx';
import type { NewIssueLinksType } from '@atlassian/jira-issue-view-base/src/content/issue-links/add/types.tsx';
import { MAX_SELECTED_ISSUES_FOR_DEPENDENCY_LINES } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import { getIssueLinksAnalytics } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/utils.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueLink as BoardIssueLink } from '@atlassian/jira-software-board-common/src/index.tsx';
import {
	getIssueLinkTypes,
	getIssueTypes,
} from '@atlassian/jira-software-board/src/state/selectors/software/software-selectors.tsx';
import { getIssuesEntriesWithIssueLinks } from '@atlassian/jira-software-board/src/state/selectors/work/work-selectors.tsx';
import {
	ActionSubject,
	IncrementPlanningAnalyticsKey,
	Action,
	AnalyticsEvent,
} from '@atlassian/jira-software-view-settings/src/common/types/analytics.tsx';
import { useSendAnalyticsEvent } from '@atlassian/jira-software-view-settings/src/common/utils/hooks/use-send-analytics-event/index.tsx';
import {
	useIssueIdsToShowDependencies,
	useViewSettingsActions,
} from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { wrapActionWithPromise } from '../../../../../../../common/utils/increment-planning/index.tsx';
import {
	issueLinksRemoveRequest,
	issueLinkCreateRequest,
	reachDependencyLinesLimitInIPBoard,
} from '../../../../../../../state/actions/issue/issue-link/index.tsx';
import {
	useBoardSelector,
	useBoardActionCreator,
	useBoardDispatch,
} from '../../../../../../../state/index.tsx';
import { isIncrementPlanningReadOnly } from '../../../../../../../state/selectors/board/board-permissions-selectors.tsx';
import {
	getIssueLinksForDependenciesFlyout,
	getIssueLinksForDependenciesModal,
} from '../../../../../../../state/selectors/issue-link/issue-link-selectors.tsx';
import {
	getIssueById,
	getIssueTypeById,
} from '../../../../../../../state/selectors/issue/issue-selectors.tsx';
import {
	getPlanId,
	getScenarioId,
	getIssues,
	rapidViewIdSelector,
} from '../../../../../../../state/selectors/software/software-selectors.tsx';
import { INWARD_LINK_DIRECTION } from '../../../../../../modals/link-issue-modal/constants.tsx';
import type { IssueLinkType } from '../../../../../../modals/link-issue-modal/types.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const IssueLinksDetailsGroupWrapper = (props: Props) => {
	const { formatMessage } = useIntl();

	const sendAnalyticsEvent = useSendAnalyticsEvent();

	const selectedIssueIdsForShowDependencyLines = useIssueIdsToShowDependencies();
	const { setIssueIdsToShowDependencies } = useViewSettingsActions();
	const boardId = Number(useBoardSelector(rapidViewIdSelector));
	const [isShowLinesChecked, setIsShowLinesChecked] = useState(
		selectedIssueIdsForShowDependencyLines.includes(`${props.issueId}`),
	);
	const issue = useBoardSelector((state) => getIssueById(state, props.issueId));
	const issueType = useBoardSelector((state) => getIssueTypeById(state, issue.typeId));
	const issues = useBoardSelector((state) => getIssues(state));
	const issuesArray = useMemo(() => Object.values(issues), [issues]);
	const {
		internal: issueLinks,
		external,
	}: { internal: { [relationName: string]: IssueLink[] }; external: number } = useBoardSelector(
		(state) =>
			getIssueLinksForDependenciesFlyout(state)(
				props.issueId,
				fg('dependency_visualisation_program_board_fe_and_be')
					? formatMessage(messages.offtrackLabel)
					: undefined,
			),
	);
	const issueLinkAndDirectionMap = useBoardSelector((state) =>
		getIssueLinksForDependenciesModal(state)(props.issueId),
	);
	const [maxIssuesToDisplay, setMaxIssuesToDisplay] = useState<number | undefined>(undefined);
	const allInternalLinks = flatten(Object.values(issueLinks));

	const isIPBoardReadOnly = useBoardSelector(isIncrementPlanningReadOnly);

	const issuesWithLinksById = useBoardSelector(getIssuesEntriesWithIssueLinks);
	const issueLinkTypes = useBoardSelector(getIssueLinkTypes);
	const issueTypes = useBoardSelector(getIssueTypes);

	// this is a hacky way to aviod cyclic dependency between view-settings and software board. It can go away if Issue type and CardType type gets extracted to board common.
	const issuesWithLinksByIdAnalytics = useMemo(
		() =>
			mapValues(issuesWithLinksById, (i) => ({
				typeId: i.typeId,
				issueLinks: i.issueLinks,
			})),
		[issuesWithLinksById],
	);

	const issueTypesAnalytics = useMemo(
		() =>
			mapValues(issueTypes, (t) => ({
				hierarchyLevelType: t.hierarchyLevelType,
			})),
		[issueTypes],
	);

	const { setIssueLinkModalEntryPointSubject } = useIssueLinkedModalEntrypointActions();
	const dispatch = useBoardDispatch();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onUnlinkIssueAction = useBoardActionCreator((boardIssueLink: BoardIssueLink) =>
		issueLinksRemoveRequest({
			issueId: props.issueId,
			issueLink: boardIssueLink,
		}),
	);
	const planId = useBoardSelector(getPlanId);
	const scenarioId = useBoardSelector(getScenarioId);

	const onUnlinkIssue = useCallback(
		async (issueLink: IssueLink) => {
			if (issueLink.issueLinkId) {
				const boardIssueLink = issue.issueLinks
					? issue.issueLinks.find((link) => link.id === issueLink.issueLinkId)
					: undefined;
				if (boardIssueLink) {
					fireUIAnalytics(
						createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
						'unlinkIssue',
					);

					return onUnlinkIssueAction(boardIssueLink);
				}
			}
		},
		[onUnlinkIssueAction, issue.issueLinks, createAnalyticsEvent],
	);

	const onLinkIssueRequest = useCallback(
		async (
			issueId: string | number,
			newIssueLinks: NewIssueLinksType[],
			issueLinkType: IssueLinkType,
		) => {
			const isInwards = issueLinkType.direction === INWARD_LINK_DIRECTION;

			return Promise.all(
				newIssueLinks.map(({ value }) =>
					wrapActionWithPromise(
						dispatch,
						issueLinkCreateRequest({
							sourceItemKey: `${isInwards ? value : issueId}`,
							targetItemKey: `${isInwards ? issueId : value}`,
							type: Number(issueLinkType.id),
						}),
					),
				),
			);
		},
		[dispatch],
	);

	const renderExternalLinksMessage = () => (
		<Stack space="space.050" xcss={[allInternalLinks.length > 0 && externalIssueLinksStyles]}>
			<Box xcss={titleStyles}>{formatMessage(messages.externalIssueLinksTitle)}</Box>
			<Box paddingBlockEnd="space.075">
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.externalIssueLinksDescriptionIssueTermRefresh
						: messages.externalIssueLinksDescription,
					{
						externalIssueLinksCount: external,
					},
				)}
			</Box>
		</Stack>
	);

	const handleShowLinesToggleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const isChecked = event.target.checked;

			const attributes = getIssueLinksAnalytics(
				issue.issueLinks,
				props.issueId,
				issueLinkTypes,
				issuesWithLinksByIdAnalytics,
				issueTypesAnalytics,
			);

			if (
				!isShowLinesChecked &&
				selectedIssueIdsForShowDependencyLines.length >= MAX_SELECTED_ISSUES_FOR_DEPENDENCY_LINES
			) {
				setIsShowLinesChecked(false);
				dispatch(reachDependencyLinesLimitInIPBoard(MAX_SELECTED_ISSUES_FOR_DEPENDENCY_LINES));

				sendAnalyticsEvent(
					Action.CHANGED,
					ActionSubject.TOGGLE,
					AnalyticsEvent.UI_EVENT,
					{
						key: IncrementPlanningAnalyticsKey.ISSUE_IDS_TO_SHOW_DEPENDENCIES,
						NumberOfCardDependencies: issue.issueLinks?.length,
						ToggledOn: isChecked,
						ToggleFailed: true,
						...attributes,
					},
					'increment-planning-board',
				);

				return;
			}
			setIsShowLinesChecked(isChecked);
			if (isChecked) {
				const newIds = [...selectedIssueIdsForShowDependencyLines, `${props.issueId}`];
				setIssueIdsToShowDependencies(boardId, Array.from(new Set(newIds)));
			} else {
				setIssueIdsToShowDependencies(
					boardId,
					selectedIssueIdsForShowDependencyLines.filter((id) => `${id}` !== `${props.issueId}`),
				);
			}

			sendAnalyticsEvent(
				Action.CHANGED,
				ActionSubject.TOGGLE,
				AnalyticsEvent.UI_EVENT,
				{
					key: IncrementPlanningAnalyticsKey.ISSUE_IDS_TO_SHOW_DEPENDENCIES,
					NumberOfCardDependencies: issue.issueLinks?.length,
					ToggledOn: isChecked,
					...attributes,
				},
				'increment-planning-board',
			);
		},
		[
			issue.issueLinks,
			props.issueId,
			issueLinkTypes,
			issuesWithLinksByIdAnalytics,
			issueTypesAnalytics,
			isShowLinesChecked,
			selectedIssueIdsForShowDependencyLines,
			sendAnalyticsEvent,
			dispatch,
			setIssueIdsToShowDependencies,
			boardId,
		],
	);

	const renderToggleDependencyLinesAction = () => {
		if (!fg('dependency_visualisation_program_board_fe_and_be')) {
			return undefined;
		}

		return (
			<Inline alignBlock="center">
				<Text size="small" color="color.text.subtlest">
					{formatMessage(messages.showLinesTitle)}
				</Text>
				<Tooltip
					content={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.tooltipForShowLinesToggleIssueTermRefresh
							: messages.tooltipForShowLinesToggle,
					)}
				>
					<Toggle
						isChecked={isShowLinesChecked}
						onChange={handleShowLinesToggleChange}
						label={formatMessage(messages.labelForShowLinesToggle)}
						testId="software-board.board-container.board.card-container.card.issue-links-indicator.flyout.toggle"
					/>
				</Tooltip>
			</Inline>
		);
	};

	const flyoutOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
		// Stop the flyout from triggering the onClick of the issue card, preventing the issue view modal from appearing
		event.stopPropagation();
	};

	const canViewInReport =
		props.issueId && planId && scenarioId && fg('dependency_visualisation_program_board_fe_and_be');

	return (
		<Box
			padding="space.200"
			xcss={fg('dependency_visualisation_program_board_fe_and_be') && flyoutWrapper}
			onClick={fg('issue_view_in_program_board') ? flyoutOnClick : undefined}
		>
			<IssueLinksDetailsGroup
				unlinkIssueButtonTooltipText={formatMessage(messages.removeDependency)}
				title={formatMessage(messages.title)}
				onUnlinkIssue={onUnlinkIssue}
				showBlockedTime={false}
				maxIssuesToDisplay={maxIssuesToDisplay}
				exceedMaxSizeLinkOptions={{
					text: formatMessage(messages.viewAllDependencies),
					onClick: () => {
						setMaxIssuesToDisplay(Infinity);
					},
				}}
				{...(canViewInReport &&
					fg('smart_links_for_plans') && {
						viewInReportLinkOptions: {
							text: formatMessage(messages.viewInDependenciesTab),
							href: `/jira/plans/${planId}/scenarios/${scenarioId}/dependencies?issueFilter=${props.issueId}`,
						},
					})}
				leftFooterLinkOptions={
					!isIPBoardReadOnly
						? {
								text: formatMessage(messages.addDependency),
								icon: fg('enghealth-12479-jsw-board-visual-refresh') ? (
									<AddIcon
										label={formatMessage(
											fg('jira-issue-terminology-refresh-m3')
												? messages.unlinkIssuesIssueTermRefresh
												: messages.unlinkIssues,
										)}
										LEGACY_fallbackIcon={AddIconOld}
										color={token('color.icon')}
									/>
								) : (
									<AddIconOld
										label={formatMessage(
											fg('jira-issue-terminology-refresh-m3')
												? messages.unlinkIssuesIssueTermRefresh
												: messages.unlinkIssues,
										)}
									/>
								),
								onClick: () => {
									setIssueLinkModalEntryPointSubject({
										issueData: {
											issueId: String(issue.id),
											issueKey: issue.key,
											issueSummary: issue.summary,
											issueType,
										},
										modalContent: {
											title: formatMessage(messages.addDependencyKey),
											showDescription: false,
											showCreateIssueLinkButton: false,
											showFeedbackCollector: false,
											localIssuesOnly: true,
											confirmLabel: formatMessage(messages.addDependencyCta),
											issues: issuesArray,
											onLinkIssueRequest,
											issueLinks: issueLinkAndDirectionMap,
										},
									});
								},
							}
						: undefined
				}
				issueLinkGroups={issueLinks}
				showFeedbackCollector={false}
				{...(external > 0 && {
					renderExternalLinksMessage,
				})}
				hideTitleWhenEmpty
				canUserLinkIssue={!isIPBoardReadOnly}
				rightHeaderActions={renderToggleDependencyLinesAction()}
			/>
		</Box>
	);
};

export default IssueLinksDetailsGroupWrapper;

const externalIssueLinksStyles = xcss({
	paddingBlockStart: 'space.100',
});

const titleStyles = xcss({
	textTransform: 'uppercase',
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
});

const flyoutWrapper = xcss({
	minWidth: '360px',
	maxWidth: '528px',
});
