import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { CREATE_ISSUE_ACTIVE_SPRINT_EMPTY_VIEW } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { useBoardSelector } from '../../../../../state/index.tsx';
import { isBacklogEnabled } from '../../../../../state/selectors/board/board-selectors.tsx';
import {
	getIssueProjectsPermissionGroup,
	projectTypeSelector,
} from '../../../../../state/selectors/software/software-selectors.tsx';
import emptyBoardImage from './assets/empty-cmp-kanban-board.svg';
import messages from './messages.tsx';

const EmptyCMPKanbanBoardMessage = () => {
	const { formatMessage } = useIntl();
	const { projectsWithCreatePermission } = useBoardSelector(getIssueProjectsPermissionGroup);
	const hasCreateIssuePermission = projectsWithCreatePermission.length > 0;
	const hasBacklog = useBoardSelector(isBacklogEnabled);
	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	const isJSMBoard = useBoardSelector(projectTypeSelector) === SERVICE_DESK_PROJECT;

	const renderTitleText = () => {
		if (isJSMBoard) {
			return formatMessage(messages.jsmEmptyStateTitle);
		}

		if (hasCreateIssuePermission) {
			return hasBacklog
				? formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.titleWithPermissionAndBacklogIssueTermRefresh
							: messages.titleWithPermissionAndBacklog,
					)
				: formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.titleWithPermissionNoBacklogIssueTermRefresh
							: messages.titleWithPermissionNoBacklog,
					);
		}

		return formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.titleNoPermissionIssueTermRefresh
				: messages.titleNoPermission,
		);
	};

	const renderAction = () => {
		if (hasCreateIssuePermission) {
			const openIssueCreate = () => {
				openIssueCreateModal({
					payload: { project: { projectId: String(projectsWithCreatePermission[0]) } },
					triggerPointKey: CREATE_ISSUE_ACTIVE_SPRINT_EMPTY_VIEW,
				});
			};
			return (
				<Button
					onClick={openIssueCreate}
					testId="software-board.board-container.board.column.empty-cmp-kanban-dialog.button"
				>
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.buttonIssueTermRefresh
							: messages.button,
					)}
				</Button>
			);
		}

		return isJSMBoard ? null : (
			<TextStyle>
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.descriptionNoPermissionIssueTermRefresh
						: messages.descriptionNoPermission,
				)}
			</TextStyle>
		);
	};

	return (
		<Container>
			<Image src={emptyBoardImage} alt="empty kanban board" />
			<TitleStyle hasCreateIssuePermission={hasCreateIssuePermission} hasDescription={isJSMBoard}>
				{renderTitleText()}
			</TitleStyle>
			{isJSMBoard && (
				<DescriptionContainer hasCreateIssuePermission={hasCreateIssuePermission}>
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.jsmEmptyStateDescriptionIssueTermRefresh
							: messages.jsmEmptyStateDescription,
					)}
				</DescriptionContainer>
			)}
			{renderAction()}
		</Container>
	);
};

export default EmptyCMPKanbanBoardMessage;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	padding: token('space.400', '32px'),
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 16}px`,
	marginBottom: token('space.300', '24px'),
});

const getMargin = (hasCreateIssuePermission: boolean, hasDescription: boolean) => {
	if (hasDescription) {
		return token('space.100', '8px');
	}

	return hasCreateIssuePermission ? token('space.300', '24px') : token('space.100', '8px');
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleStyle = styled.div<{
	hasCreateIssuePermission: boolean;
	hasDescription: boolean;
}>({
	textAlign: 'center',
	fontWeight: 'bold',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: ({ hasCreateIssuePermission, hasDescription }) =>
		getMargin(hasCreateIssuePermission, hasDescription),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextStyle = styled.div({
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionContainer = styled.div<{ hasCreateIssuePermission: boolean }>({
	textAlign: 'center',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: ({ hasCreateIssuePermission }) =>
		hasCreateIssuePermission ? `${token('space.300', '24px')}` : `${token('space.100', '8px')}`,
});
