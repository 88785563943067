import React, { type ComponentType, memo } from 'react';
import { styled } from '@compiled/react';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import ArrowRightIconOld from '@atlaskit/icon/glyph/arrow-right';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import StatusLozenge from '@atlassian/jira-common-components-status-lozenge/src/view.tsx';
import type { StatusCategory } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	layout,
	zIndex,
	columnHeaderHeight as headerHeight,
	columnFixedWidth,
	columnHeaderFontSize,
} from '../../../common/constants/styles/index.tsx';
import type { IssueStatus } from '../../../common/types.tsx';

export type ColumnHeaderTransitionProps = {
	isTransitionGlobal: boolean;
	target: string;
	sourceStatus: IssueStatus | null | undefined;
	targetStatusCategory: StatusCategory;
	source: string;
};

const ColumnHeaderTransitionOverlay: ComponentType<ColumnHeaderTransitionProps> = memo(
	({
		isTransitionGlobal,
		target,
		targetStatusCategory,
		source,
		sourceStatus,
	}: ColumnHeaderTransitionProps) => (
		<Container data-testid="platform-board-kit.ui.column.column-header-transition-overlay.container">
			{isTransitionGlobal && sourceStatus && sourceStatus.category ? (
				<StatusLozenge category={sourceStatus.category} name={sourceStatus.name} />
			) : (
				<TransitionNameContainer>{source}</TransitionNameContainer>
			)}
			{fg('tnk-1614-visual-refresh-boardkit-icons') ? (
				<ArrowRightIcon
					label=""
					LEGACY_fallbackIcon={ArrowRightIconOld}
					LEGACY_size="small"
					color={token('color.icon')}
				/>
			) : (
				<ArrowRightIconOld size="small" label="" />
			)}
			<StatusLozenge category={targetStatusCategory} name={target} />
		</Container>
	),
);

export default ColumnHeaderTransitionOverlay;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TransitionNameContainer = styled.span({
	textTransform: 'uppercase',
	fontWeight: 500,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${columnFixedWidth - layout.smallGutter * 4}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${headerHeight}px`,
	background: 'inherit',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.stickyHeaders,
	alignItems: 'center',
	justifyContent: 'left',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginLeft: `${layout.smallGutter * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${columnHeaderFontSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:last-child': {
			flex: '0 0 auto',
		},
	},
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	Container,
};
