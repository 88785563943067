/** @jsx jsx */
import React, { memo, useCallback, type MouseEvent } from 'react';
import { jsx, css, styled as styled2 } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { HighlightText, shouldHighlightText } from '../highlight-text/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const CardKey = memo(
	({
		text,
		highlight = [],
		onClick,
		isDone = false,
		stopEventPropagation = true,
		openInNewTab = false,
	}: Props) => {
		const { formatMessage } = useIntl();
		const highlightedText =
			highlight && shouldHighlightText(text, highlight) ? (
				<HighlightText phrases={highlight} content={text} />
			) : (
				text
			);

		const onClickKey = useCallback(
			(event: React.MouseEvent<HTMLAnchorElement>) => {
				const isModifierClick = event.ctrlKey || event.altKey || event.metaKey || event.shiftKey;
				stopEventPropagation && event.stopPropagation();

				if (!isModifierClick && !openInNewTab) {
					event.preventDefault();
					onClick?.(event);
				}
			},
			[onClick, openInNewTab, stopEventPropagation],
		);

		// prevent the context menu and Bento from opening
		const onContextMenu = useCallback((event: MouseEvent) => event.stopPropagation(), []);

		return (
			<Container data-testid="platform-card.common.ui.key.key">
				<Tooltip content={text}>
					<StyledLink
						isLineThrough={Boolean(isDone)}
						href={`/browse/${text}`}
						target="_blank"
						onContextMenu={onContextMenu}
						onClick={onClickKey}
						{...(Boolean(isDone) && {
							'aria-label': formatMessage(messages.label, {
								issueKey: text,
							}),
						})}
					>
						{fg('jsw_enghealth_color_contrast_board_linked_issue') ? (
							<Box
								xcss={[
									KeyStyles,
									isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') && KeyRefreshStyles,
								]}
							>
								{highlightedText}
							</Box>
						) : (
							<Key
								css={[
									isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') && KeyRefreshStylesOld,
								]}
							>
								{highlightedText}
							</Key>
						)}
					</StyledLink>
				</Tooltip>
			</Container>
		);
	},
);

export default CardKey;

type StyledLinkProps = { isLineThrough: boolean };
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLink = styled2.a<StyledLinkProps>({
	userSelect: 'initial',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N100),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textDecoration: (props) => (props.isLineThrough ? 'line-through' : 'none'),
	'&:focus, &:hover, &:visited, &:link, &:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		textDecoration: (props) => (props.isLineThrough ? 'line-through' : 'none'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled2.div({
	flex: '0 1 auto',
	direction: 'rtl',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	'&:focus-within': {
		outline: `${token('border.width.outline', '2px')} auto
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
            ${token('color.border.focused', colors.B200)}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Key = styled2.span({
	display: 'block',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	font: token('font.body.UNSAFE_small'),
	fontWeight: 600,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N100),
});

const KeyRefreshStylesOld = css({
	fontWeight: token('font.weight.medium'),
});

const KeyStyles = xcss({
	display: 'block',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	font: 'font.body.UNSAFE_small',
	fontWeight: 600,
	color: 'color.text.subtle',
});

const KeyRefreshStyles = xcss({
	fontWeight: token('font.weight.medium'),
});
