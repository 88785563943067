import React, { useCallback, useEffect, useMemo } from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import type { Extension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { InvisibleDiv } from '@atlassian/jira-forge-ui/src/common/ui/components/invisible-div/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useForgeApps, type OpenedForgeApp } from './index.tsx';
import { messages } from './messages.tsx';

type TargetForgeApp<T extends OpenedForgeApp, E = T['extension'], D = T['extensionData']> = Pick<
	T,
	'id'
> & {
	extension: E;
	extensionData: D & { action?: string };
};

const toTargetForgeApp = <T extends OpenedForgeApp>(app: T): TargetForgeApp<T> => {
	return {
		id: app.id,
		extension: app.extension,
		extensionData: app.action ? { ...app.extensionData, action: app.action } : app.extensionData,
	};
};

export type ForgeAppRenderer<
	E extends Extension,
	D extends Record<string, unknown>,
	T = TargetForgeApp<OpenedForgeApp<E, D>>,
> = React.ComponentType<{
	localId: string;
	app: T;
	setLoaded: () => void;
	closeApp: () => void;
	isLoading: boolean;
	// this function can be used to show error in a flag. It is optional and the module can handle the error in its own way.
	handleError?: (error?: string) => void;
}>;

type BaseForgeAppRendererProps<E extends Extension, D extends Record<string, unknown>> = {
	app: OpenedForgeApp<E, D>;
	setAppLoaded: (appId: string, action?: string) => void;
	setAppClose: (appId: string, action?: string) => void;
	renderer: ForgeAppRenderer<E, D>;
};

const BaseForgeAppRenderer = <E extends Extension, D extends Record<string, unknown>>({
	app,
	setAppLoaded,
	setAppClose,
	renderer: ForgeAppRendererComponent,
}: BaseForgeAppRendererProps<E, D>) => {
	const { formatMessage } = useIntl();
	const localId = app.extensionData.action
		? `${app.id},action:${app.extensionData.action}`
		: app.id;

	const setLoaded = useCallback(() => {
		app.isLoading && setAppLoaded(app.id, app.action);
	}, [app.id, app.action, app.isLoading, setAppLoaded]);

	const closeApp = useCallback(() => {
		setAppClose(app.id, app.action);
	}, [app.id, app.action, setAppClose]);

	const targetApp = useMemo(() => toTargetForgeApp(app), [app]);

	const { showFlag } = useFlagsService();
	const handleError = useCallback(
		(msg?: string) => {
			showFlag({
				key: localId,
				type: 'error',
				title: formatMessage(messages.appErrorTitle),
				description: msg ?? formatMessage(messages.appErrorDescription),
			});
		},
		[showFlag, localId, formatMessage],
	);

	return (
		<ForgeAppRendererComponent
			localId={localId}
			app={targetApp}
			setLoaded={setLoaded}
			closeApp={closeApp}
			isLoading={app.isLoading}
			handleError={handleError}
		/>
	);
};

type ForgeAppsRuntimeContainerProps<T> = {
	forgeAppRenderer: T;
};

export const ForgeAppsRuntimeContainer = <
	E extends Extension,
	D extends Record<string, unknown>,
	U = ForgeAppRenderer<E, D>,
>({
	forgeAppRenderer,
}: ForgeAppsRuntimeContainerProps<U>) => {
	const [{ openedApps }, { setAppLoaded, closeApps, closeApp }] = useForgeApps();

	useEffect(() => {
		return () => {
			closeApps();
		};
	}, [closeApps]);

	return (
		<InvisibleDiv>
			{openedApps.map((app) => (
				<BaseForgeAppRenderer<E, D>
					key={app.uuid}
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					app={app as OpenedForgeApp<E, D>}
					setAppLoaded={setAppLoaded}
					setAppClose={closeApp}
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					renderer={forgeAppRenderer as ForgeAppRenderer<E, D>}
				/>
			))}
		</InvisibleDiv>
	);
};
