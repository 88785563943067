import { useFlagsService } from '@atlassian/jira-flags'; // transactional message type (error)
import { toFlagId } from '@atlassian/jira-flags/src/services/types';
import message from './messages.tsx';

export const CommerceApiErrorFlag = () => {
	const flagKey = toFlagId('commerce-api-error');
	const { showFlag } = useFlagsService();

	showFlag({
		id: flagKey,
		key: flagKey,
		type: 'error',
		title: message.title,
		description: message.description,
		isAutoDismiss: true,
	});
	return null;
};
